import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Unit-testing Client and Service";
export const _frontmatter = {};
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "unit-testing-client-and-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#unit-testing-client-and-service",
        "aria-label": "unit testing client and service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Unit-testing `}<inlineCode parentName="h1">{`Client`}</inlineCode>{` and `}<inlineCode parentName="h1">{`Service`}</inlineCode></h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#using-a-fake-context-to-emulate-an-incoming-request"
        }}>{`Using a fake context to emulate an incoming request`}</a></li>
    </ul>
    <p>{`A unit test of a client or a service will require you to prepare two objects:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ClientRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html"
        }}>{`type://ClientRequestContext`}</a>{` or `}<a parentName="li" {...{
          "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
        }}>{`type://ServiceRequestContext`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
        }}>{`type://HttpRequest`}</a>{` or `}<a parentName="li" {...{
          "href": "type://RpcRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RpcRequest.html"
        }}>{`type://RpcRequest`}</a></li>
    </ul>
    <p><a parentName="p" {...{
        "href": "type://ClientRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html"
      }}>{`type://ClientRequestContext`}</a>{` or `}<a parentName="p" {...{
        "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
      }}>{`type://ServiceRequestContext`}</a>{` is a more complex object with many properties than
`}<a parentName="p" {...{
        "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
      }}>{`type://HttpRequest`}</a>{` or `}<a parentName="p" {...{
        "href": "type://RpcRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RpcRequest.html"
      }}>{`type://RpcRequest`}</a>{`, and thus Armeria provides the API dedicated to building a fake context
object easily:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import org.junit.jupiter.api.BeforeEach;
import org.junit.jupiter.api.Test;

import com.linecorp.armeria.common.HttpRequest;
import com.linecorp.armeria.common.HttpResponse;
import com.linecorp.armeria.common.AggregatedHttpResponse;
import com.linecorp.armeria.client.ClientRequestContext;
import com.linecorp.armeria.server.ServiceRequestContext;

class MyJUnit5Test {

    private MyClient client;
    private MyService service;

    @BeforeEach
    void setUp() {
        client = ...;
        service = ...;
    }

    @Test
    void testClient() {
        // Given
        HttpRequest req = HttpRequest.of(HttpMethod.GET, "/greet?name=foo");
        ClientRequestContext cctx = ClientRequestContext.of(req);

        // When
        HttpResponse res = client.execute(cctx, req);

        // Then
        AggregatedHttpResponse aggregatedRes = res.aggregate().join();
        assertEquals(200, aggregatedRes.status().code());
    }

    @Test
    void testService() {
        // Given
        HttpRequest req = HttpRequest.of(HttpMethod.POST, "/greet",
                                         MediaType.JSON_UTF_8,
                                         "{ \\"name\\": \\"foo\\" }");
        ServiceRequestContext sctx = ServiceRequestContext.of(req);

        // When
        HttpResponse res = service.serve(sctx, req);

        // Then
        AggregatedHttpResponse aggregatedRes = res.aggregate().join();
        assertEquals(200, aggregatedRes.status().code());
    }
}
`}</code></pre>
    <p>{`Although the fake context returned by `}<a parentName="p" {...{
        "href": "type://ClientRequestContext#of(HttpRequest):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html#of(com.linecorp.armeria.common.HttpRequest)"
      }}>{`type://ClientRequestContext#of(HttpRequest)`}</a>{` and
`}<a parentName="p" {...{
        "href": "type://ServiceRequestContext#of(HttpRequest):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#of(com.linecorp.armeria.common.HttpRequest)"
      }}>{`type://ServiceRequestContext#of(HttpRequest)`}</a>{` will provide sensible defaults,
you can override its default properties using a builder:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import java.net.InetAddress;
import java.net.InetSocketAddress;
import java.util.Map;

import com.linecorp.armeria.common.SessionProtocol;
import com.linecorp.armeria.client.ClientRequestContext;
import com.linecorp.armeria.server.RoutingResult;
import com.linecorp.armeria.server.ServiceRequestContext;

HttpRequest req = HttpRequest.of(...);

ClientRequestContext cctx =
        ClientRequestContext.builder(req)
                            .sessionProtocol(SessionProtocol.H1C)
                            .remoteAddress(new InetSocketAddress("192.168.0.2", 443))
                            .build();

RoutingResult routingResult =
        RoutingResult.builder()
                     .path("/mapped/path")                       // Mapped path
                     .query("foo=bar&baz=qux")                   // Query string
                     .pathParams(Map.of("pathParam1", "value1",  // Path parameters
                                        "pathParam2", "value2"))
                     .build();

ServiceRequestContext sctx =
        ServiceRequestContext.builder(req)
                             .clientAddress(InetAddress.getByName("192.168.1.2"))
                             .routingResult(routingResult);
                             .build();
`}</code></pre>
    <h2 {...{
      "id": "using-a-fake-context-to-emulate-an-incoming-request",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-a-fake-context-to-emulate-an-incoming-request",
        "aria-label": "using a fake context to emulate an incoming request permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using a fake context to emulate an incoming request`}</h2>
    <p>{`It is usually not necessary to build a context object by yourself except when writing a unit test,
because Armeria will always create a context object for you. However, you may need to build a fake context and
invoke your request processing pipeline with it when you want to handle the requests received via other sources
such as:`}</p>
    <ul>
      <li parentName="ul">{`Non-Armeria services`}</li>
      <li parentName="ul">{`Non-HTTP protocols, e.g. Kafka and STOMP`}</li>
      <li parentName="ul">{`Timers, i.e. Trigger a certain request every N minutes.`}</li>
    </ul>
    <p>{`The following example shows how to emit a fake request every minute:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import java.util.concurrent.ScheduledExecutorService;
import java.util.concurrent.TimeUnit;

import com.linecorp.armeria.server.HttpService;

ScheduledExecutorService executor = ...;
HttpService sessionManagementService = (ctx, req) -> ...;

// Send a session expiration request to the session management service
// every minute.
executor.scheduleWithFixedDelay(() -> {
    HttpRequest req = HttpRequest.of(HttpMethod.POST, "/expire_stall_sessions");
    ServiceRequestContext ctx = ServiceRequestContext.of(req);
    try {
        HttpResponse res = sessionManagementService.serve(ctx, req);
        AggregatedHttpResponse aggregatedRes = res.aggregate().get();
        if (aggregatedRes.status().code() != 200) {
            System.err.println("Failed to expire stall sessions: " +
                               aggregatedRes);
        }
    } catch (Exception e) {
        e.printStackTrace();
    }
}, 1, 1, TimeUnit.MINUTES);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      